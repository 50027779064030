import React from "react";
import { useStaticQuery, graphql } from "gatsby";

import { Layout } from "../../components/shared/layout";
import { Helmet } from "../../components/shared/helmet";
import { PageTitle } from "../../components/shared/page-title";

import { FoodMenu } from "../../components/speisekarte/food-menu";
import { FullHeight } from "../../components/shared/full-height";

export default () => {
  const { file0, file1 } = useStaticQuery(graphql`
    query {
      file0: file(relativePath: { eq: "speisekarte-cafe-paris-neu-01.png" }) {
        childImageSharp {
          fluid(maxWidth: 1707) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      file1: file(relativePath: { eq: "speisekarte-cafe-paris-neu-02.png" }) {
        childImageSharp {
          fluid(maxWidth: 1707) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  return (
    <Layout>
      <Helmet title="Kultur Café Paris - Speisekarte" />
      <PageTitle>
        KULTUR CAFÉ PARIS
        <br />
        SPEISEKARTE
      </PageTitle>
      <FullHeight>
        <FoodMenu fluids={[file0.childImageSharp.fluid]} />
        <FoodMenu fluids={[file1.childImageSharp.fluid]} />
      </FullHeight>
    </Layout>
  );
};
